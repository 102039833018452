import { kitchenAxios } from '../utils/api';

export const getCateringsSchedulesFoodBatch = async (
  type,
  date,
  sort,
  direction,
  foodBatchKeyword
) =>
  await kitchenAxios.get(`caterings/schedules/${type}/foods`, {
    params: {
      date,
      sort_by: sort,
      direction,
      keyword: foodBatchKeyword
    }
  });

export const getCateringsSchedulesOrders = async (type, date, orderKeyword, sort, direction) =>
  await kitchenAxios.get(`caterings/schedules/${type}/orders`, {
    params: {
      date,
      keyword: orderKeyword,
      sort_by: sort,
      direction
    }
  });

export const getCateringsSchedulesPacked = async (type, date, sort, direction, packedKeyword) =>
  await kitchenAxios.get(`caterings/schedules/${type}/packed`, {
    params: {
      date,
      sort_by: sort,
      direction,
      keyword: packedKeyword
    }
  });

export const getCateringDrivers = async () => await kitchenAxios.get(`caterings/driver`);

export const getAvailableChef = async (station_id) =>
  await kitchenAxios.get('cooks', {
    params: {
      station_id: station_id
    }
  });

export const assignCook = async (payload) =>
  await kitchenAxios.post('caterings/assign-cook', payload);

export const getProductOrderDetail = async (id) => await kitchenAxios.get(`caterings/orders/${id}`);

export const handleCooked = async (payload) => await kitchenAxios.post('cooked', payload);

export const getCateringSchedules = async (currentDate) =>
  await kitchenAxios.get('caterings/schedules', {
    params: {
      date: currentDate
    }
  });

export const getCateringSchedulesPlans = async (id) =>
  await kitchenAxios.get(`caterings/schedules/${id}/plans?include=cateringPlanProducts.product`);

export const changeCateringPlanStatus = async (id, status) =>
  await kitchenAxios.put(`caterings/plans/${id}/set-status`, { status });

export const getCateringProductPlans = async () =>
  await kitchenAxios.get('caterings/plans/products');

export const addPlanProduct = async (planId, payload) =>
  await kitchenAxios.post(`caterings/plans/${planId}/products`, { products: payload });

export const changePlanProductStatus = async (id, status) =>
  await kitchenAxios.put(`caterings/plans/products/${id}/set-status`, { status });

export const sendPacked = async (tempIds, selectedDriver) =>
  await kitchenAxios.put(`caterings/orders/delivered`, {
    catering_plan_order_ids: tempIds,
    driver_id: selectedDriver
  });

export const getScheduledList = async (startDate, endDate) =>
  await kitchenAxios.get('caterings/scheduled', {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  });

export const addDuration = async (payload) =>
  await kitchenAxios.post('caterings/schedules', payload);

export const getCateringInvoices = () => kitchenAxios.get('caterings');
export const aprroveCateringInvoice = (id) => kitchenAxios.put(`caterings/${id}/approve`);
export const declineCateringInvoice = (id) => kitchenAxios.put(`caterings/${id}/decline`);
